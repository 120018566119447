.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--bg);
  box-shadow: var(--header-shadow);
  z-index: 3;
  margin: 0 auto;
  max-width: var(--max-content-width);
  padding: 0 20px;
}

h2.logo {
  margin: 10px 0;
}

button.theme-toggle {
  margin: 0;
  position: absolute;
  top: 0;
  right: 20px;
  border: none;
  background: none;
}

img.theme-toggle {
  height: 30px;
  padding: 0 4px;
  transform-origin: top;
  cursor: pointer;
}

img.theme-toggle.switch {
  transform: translateY(-30%);
}

button.theme-toggle:hover img.theme-toggle.switch {
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}

.nav-toggle {
  /* display: none; */
  display: block;
  margin-top: 40px;
  cursor: pointer;
}

.nav-links {
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
}

div.page-content {
  text-align: left;
  justify-content: center;
}

div.page-content#center {
  justify-content: center;
  align-items: center;
}

div.page-content p:not() {
  font-family: var(--font-nunito);
}

.page-content .typewritten {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

*#blink {
  animation: 1s blink-accent infinite;
  margin: 0;
}

.page-content .typewritten h1#name {
  margin: 0;
}

.page-content .home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin-top: 40vh;
  width: 100%;
  box-sizing: border-box;
}

.page-content .home.row, .page-content .home .row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.home .row div {
  padding-right: 15px;
}

.page-content .home.confined {
  max-width: 600px;
}

.page-content .home.connect {
  margin-bottom: 200px;
}

.page-content .home h3,
.page-content .home h2 {
  color: var(--text);
}

.page-content .home h3.section-title {
  color: var(--accent);
  font-family: var(--font-code);
}

.page-content h2 {
  margin: 10px 0;
  margin-bottom: 18px;
}

.button {
  color: var(--primary);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin: 50px 0;
  border: 1.5px solid var(--primary);
  font-family: var(--font-nunito);
}

.header .button {
  margin: 10px 0;
  margin-right: 50px;
  padding: 5px 15px;
}

.button:hover {
  border: 1.5px solid var(--accent);
  color: var(--accent);
}

a.button {
  font-weight: 600;
}

.header a.button {
  font-size: 1em;
}

.button img.icon {
  padding-left: 0;
}

.home img#about-img {
  min-width: 35%;
  box-sizing: border-box;
  margin-left: 20px;
}

ul.skill-nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
}

ul.skill-nav button {
  border-radius: 20px;
  line-height: auto;
  padding: 5px;
  margin: 0 5px;
  display: flex;
  filter: grayscale(80%);
}

ul.skill-nav button:hover {
  box-shadow: var(--hover-shadow);
  filter: none;
}

ul.skill-nav li p.title {
  margin: 0;
  display: flex;
  color: var(--accent);
  padding-right: 10px;
}

ul.skill-nav li.selected button {
  filter: none;
  background-color: var(--element-bg);
}

ul.skills {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  align-items: center;
  margin: 0;
}

ul.skills li {
  padding: 10px 20px;
  background-color: var(--element-bg);
  border-radius: 10px;
  box-sizing: border-box;
  animation: 0.25s pulse ease-in-out;
}

ul.skills p.title img.icon {
  padding-left: 0;
}

div.skill-group {
  min-height: 200px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

div.contact-links {
  position: fixed;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: var(--nav-slide-duration) nav-slide-up ease;
}

p.prompt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

p.prompt.center {
  margin-top: 150px;
  justify-content: center;
  text-align: center;
  animation: 0.8s shimmer ease-in-out alternate infinite;
}

p.prompt:hover {
  color: var(--primary);
}

.contact-links div.line {
  margin-top: 5px;
}

@media screen and (max-width: 900px) {
  ul.skills {
    flex-direction: column;
  }

  ul.skills li {
    min-width: unset;
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 500px) {
  .header {
    align-items: baseline;
    margin-left: 0px;
    box-shadow: none;
  }

  .page-content .home.row, .page-content .home .row {
    flex-direction: column;
  }

  .nav-toggle {
    display: block;
    margin-top: 40px;
    cursor: pointer;
  }
  
  div.contact-links {
    right: 10px;
  }

  .page-content#center h2 {
    font-size: 1.8em;
    white-space: nowrap;
  }

  div.line {
    display: none;
  }

  ul.skill-nav img.icon {
    padding: 0 5px;
  }
}

@keyframes stretch {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(1.2);
  }
}