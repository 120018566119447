.nav li {
  border-radius: 25px;
  background: var(--bg);
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin: 0px 10px;
  width: max-content;
  line-height: normal;
}

.nav:not(.contact) li a {
  filter: grayscale(80%);
}

.nav li:last-of-type {
  margin-right: 0;
}

.contact .nav li {
  margin: 0;
}

div:not(.contact) .nav li.reverse, .contact-links li.reverse {
  flex-direction: row-reverse;
}

.nav:not(.contact) li:hover, .contact-links li:hover {
  box-shadow: var(--hover-shadow);
  cursor: pointer;
}

.nav li a.active-link {
  filter: none;
}

.nav img.icon {
  padding: 0;
}

@media screen and (max-width: 500px) {
  .nav li {
    box-shadow: var(--hover-shadow);
    padding: 5px 10px;
    margin: 5px 10px;
    margin-right: 0;
  }
}