div.page-content {
  justify-content: initial;
  /* max-width: 1500px; */
}

.projects-parent {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.projects-parent h3.section-title {
  color: var(--primary);
  font-family: var(--font-nunito);
  margin-left: 30px;
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 50px;
  padding-bottom: 30px;
  position: sticky;
  top: 50px;
  z-index: 2;
  background-color: var(--bg);
  box-shadow: 0 30px 20px -30px var(--bg-opaque);
}

@media screen and (max-width: 500px) {
  .projects-parent h3.section-title {
    margin-left: 0;
    font-size: 1.7em;
  }
}