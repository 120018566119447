@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Nunito:wght@300family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-size: 10.5pt;
}

:root {
  --accent: rgb(79, 129, 223);
  --accent-opaque: #76a4fb80;
  --primary: #fff;
  --secondary: #7c848c;
  --element-bg: #141a1e;
  --hover-shadow: 0 0 15px rgba(43, 43, 43, 0.73);
  --bg-opaque: #10121499;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --font-nunito: 'Nunito', sans-serif;
  --font-poppins: 'Poppins', sans-serif;

  --color-wip: #df9648;
  --color-live: #4cc871;
  --color-comp: #6db2c8;

  --nav-slide-duration: 3.85s;
  --max-content-width: 1200px;
}

body[theme='dark'] {
  --accent: #76a4fb;
  --accent-light: #8fb5fd;
  --accent-opaque: #76a4fb80;
  --primary: #f2f4f7;
  --bg: #101214;
  --text: #8892b0;
  --secondary: #7c848c;
  --element-bg: #141a1e;
  --hover-shadow: 0 0px 25px #171b1fd8;
  --header-shadow: 0 10px 25px #101214b2;
  --bg-opaque: #101214de;
  scrollbar-color: var(--accent) var(--bg);
}

body[theme='light'] {
  --accent: #4f81df;
  --accent-light: #8fb5fd;
  --accent-opaque: #76a4fb80;
  --primary: #404b55;
  --bg: #e6e9ec;
  --text:#313540;
  --secondary: #7c848c;
  --element-bg: #f2f4f7;
  --hover-shadow: 0 0 15px rgba(208, 204, 204, 0.73);
  --header-shadow: 0 30px 30px #e6e9ec4b;
  --bg-opaque: #e6e9ec;
  scrollbar-color: var(--accent) var(--bg);
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-code);
  color: var(--primary);
}

h1 {
  font-size: 3.5em;
  color: var(--accent);
}

h2 {
  font-size: 2em;
  color: var(--accent);
}

h3, h4 {
  font-size: 1.25em;
  color: var(--primary);
  font-family: var(--font-nunito);
  font-weight: 400;
  margin: 0;
}

h4 {
  font-size: 1.15em;
}

body {
  margin: 0;
  font-family: var(--font-poppins);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  scrollbar-color: var(--accent) var(--bg);
}

code {
  font-family: var(--font-code);
}

p, li, input, label, textarea {
  color: var(--text);
}

li {
  line-height: 1.75em;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--text);
}

p a, li a, a.link, nav.sidebar button {
  color: var(--accent);
  font-weight: 600;
  font-family: var(--font-code);
  position: relative;
  font-size: 11.5pt;
}

p b, li b {
  background-image: linear-gradient(to bottom, var(--accent-opaque) 20%, transparent 20%, transparent 80%, var(--accent-opaque) 80%, var(--accent-opaque));
  background-position: 0 1.03em;
  background-repeat: repeat-x;
  font-weight: normal;
}

p a:after, ul:not(.nav) li a:after, a.link:after, nav.sidebar button:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: -3px;
  content: "";
  display: block;
  height: 2px;
  right: 100%;
  left: 0;
  position: absolute;
  background: var(--accent);
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}

p a:hover:after, ul:not(.nav) li a:hover:after, a.link:hover:after, nav.sidebar button:hover:after { 
  width: 100%; 
  right: 0;
}

.container {
  background-color: var(--bg);
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

img.logo {
  max-width: 60px;
  height: 35px;
  margin: 10px 0;
}

div.line {
  width: 3px;
  height: 70px;
  background-color: var(--accent);
}

div.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30px;
  color: var(--accent);
}

div.page-content {
  max-width: var(--max-content-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  padding: 0 80px;
  box-sizing: border-box;
  background-color: var(--bg);
}

div.page-content.center {
  align-items: center;
  margin-top: 170px;
}

img.icon {
  width: 20px;
  height: 20px;
}

*:not(.nav) img.icon {
  padding: 0 10px;
}

img.icon.disabled {
  opacity: 0.2;
  cursor: default;
}

.nav.contact img.icon, img.nav-toggle.icon {
  transition: all 0.2s ease-in-out;
}

.nav.contact img.icon:hover, img.nav-toggle.icon:hover {
  transform: scale(1.25);
  filter: grayscale(10%);
}

div.nav-links::before {
  top: -30%;
  opacity: 0.5;
}

div.nav-links {
  animation: var(--nav-slide-duration) nav-slide-down ease;
}

div.contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: var(--max-content-width);
  background-color: var(--bg);
  padding: 0 20px;
  box-sizing: border-box;
}

div.contact p.prompt {
  width: auto;
}

.contact ul.nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-wrap: nowrap;
  width: auto;
  margin: 0;
}

@media screen and (max-width: 900px) {
  div.page-content {
    padding: 0 40px;
  }
}

@media screen and (max-width: 500px) {
  * {
    font-size: 10.5pt;
  }

  div.page-content {
    padding: 0 15px;
    padding-top: 35px;
    margin: 60px auto;
    margin-top: 70px;
  }

  div.page-content.center {
    margin-top: 100px;
  }

  .contact.spanning-content {
    flex-wrap: wrap;
  }

  div.contact {
    position: relative;
  }

  div.contact p.prompt {
    width: 100%;
  }

  .contact ul.nav {
    display: none;
  }
}

/** Animations */
@keyframes slide-down {
  from {
    height: 0;
    transform: translateY(-30%) scaleY(0);
  }
  to {
    height: 100%;
    transform: translateY(0) scaleY(1);
  }
}

@keyframes nav-slide-down {
  from {
    top: -30%;
    opacity: 0;
  }
  75% {
    top: -30%;
    opacity: 0;
  }
  to {
    top: 80px;
    opacity: 1;
  }
}

@keyframes slide-up {
  from {
    opacity: 0.5;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes nav-slide-up {
  from {
    bottom: -30%;
    opacity: 0;
  }
  75% {
    bottom: -30%;
    opacity: 0;
  }
  to {
    bottom: 80px;
    opacity: 1;
  }
}

@keyframes blink-accent {
  from {
    color: var(--accent);
  }
  50% {
    color: transparent;
  }
  to {
    color: var(--accent);
  }
}

@keyframes shimmer {
  from {
    filter: brightness(100%) hue-rotate(0deg);
  }
  to {
    filter: brightness(150%) hue-rotate(5deg);
  }
}

@keyframes pulse {
  0% {
    opacity: 0%;
    transform: scale(1);
  }
  80% {
    opacity: 100%;
    transform: scale(1.04);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

button:not(:disabled):hover {
  filter: brightness(110%) hue-rotate(2deg);
}