div.page-content {
  justify-content: initial;
}

.bg-section {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.skills p.title, .skills a.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  /* padding: 20px; */
  min-width: 130px;
  /* margin-top: 30px; */
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  /* text-decoration: underline 4px var(--element-bg); */
}