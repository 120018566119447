div.nav:not(.visible) {
  display: none;
}

.parent:not(.bottom) {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  z-index: 5;
  transform-origin: top;
}

.parent.bottom {
  position: fixed;
  display: none;
}

div.parent {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 4;
}

div.line {
  height: 100px;
}

ul.nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  margin: 0;
}

.nav p {
  font-family: var(--font-code);
  padding: 0;
  margin: 0 5px;
  cursor: pointer;
  color: var(--accent);
}

.nav a {
  text-decoration: none;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.nav a:hover {
  border-bottom: none;
}

@media screen and (max-width: 500px) {
  div.nav.visible {
    display: block;
    position: fixed;
    top: 65px;
    bottom: 0;
    background: var(--bg);
    margin: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  div.nav.visible .parent.top, div.nav.visible .parent.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100px;
    max-width: auto;
    right: 15px;
    left: auto;
  }

  .parent.top {
    top: 85px;
  }

  .parent.bottom {
    display: block;
    bottom: 20px;
  }

  ul.nav {
    width: auto;
    justify-content: flex-end;
    align-items: flex-end;
    animation: slide-down 0.15s linear;
    transform-origin: top;
  }

  .alt-links ul.nav {
    justify-content: center;
  }

  ul.nav p {
    font-size: 1.15em;
  }
}