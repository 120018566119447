@import url(https://fonts.googleapis.com/css2?family=Poppins&family=Nunito:wght@300family=Roboto+Mono&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
* {
  font-size: 10.5pt;
}

:root {
  --accent: rgb(79, 129, 223);
  --accent-opaque: #76a4fb80;
  --primary: #fff;
  --secondary: #7c848c;
  --element-bg: #141a1e;
  --hover-shadow: 0 0 15px rgba(43, 43, 43, 0.73);
  --bg-opaque: #10121499;
  --font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  --font-nunito: 'Nunito', sans-serif;
  --font-poppins: 'Poppins', sans-serif;

  --color-wip: #df9648;
  --color-live: #4cc871;
  --color-comp: #6db2c8;

  --nav-slide-duration: 3.85s;
  --max-content-width: 1200px;
}

body[theme='dark'] {
  --accent: #76a4fb;
  --accent-light: #8fb5fd;
  --accent-opaque: #76a4fb80;
  --primary: #f2f4f7;
  --bg: #101214;
  --text: #8892b0;
  --secondary: #7c848c;
  --element-bg: #141a1e;
  --hover-shadow: 0 0px 25px #171b1fd8;
  --header-shadow: 0 10px 25px #101214b2;
  --bg-opaque: #101214de;
  scrollbar-color: rgb(79, 129, 223) var(--bg);
  scrollbar-color: var(--accent) var(--bg);
}

body[theme='light'] {
  --accent: #4f81df;
  --accent-light: #8fb5fd;
  --accent-opaque: #76a4fb80;
  --primary: #404b55;
  --bg: #e6e9ec;
  --text:#313540;
  --secondary: #7c848c;
  --element-bg: #f2f4f7;
  --hover-shadow: 0 0 15px rgba(208, 204, 204, 0.73);
  --header-shadow: 0 30px 30px #e6e9ec4b;
  --bg-opaque: #e6e9ec;
  scrollbar-color: rgb(79, 129, 223) var(--bg);
  scrollbar-color: var(--accent) var(--bg);
}

h1, h2, h3, h4, h5, h6 {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: var(--font-code);
  color: #fff;
  color: var(--primary);
}

h1 {
  font-size: 3.5em;
  color: rgb(79, 129, 223);
  color: var(--accent);
}

h2 {
  font-size: 2em;
  color: rgb(79, 129, 223);
  color: var(--accent);
}

h3, h4 {
  font-size: 1.25em;
  color: #fff;
  color: var(--primary);
  font-family: 'Nunito', sans-serif;
  font-family: var(--font-nunito);
  font-weight: 400;
  margin: 0;
}

h4 {
  font-size: 1.15em;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-family: var(--font-poppins);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  scrollbar-color: rgb(79, 129, 223) var(--bg);
  scrollbar-color: var(--accent) var(--bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: var(--font-code);
}

p, li, input, label, textarea {
  color: var(--text);
}

li {
  line-height: 1.75em;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--text);
}

p a, li a, a.link, nav.sidebar button {
  color: rgb(79, 129, 223);
  color: var(--accent);
  font-weight: 600;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-family: var(--font-code);
  position: relative;
  font-size: 11.5pt;
}

p b, li b {
  background-image: linear-gradient(to bottom, #76a4fb80 20%, transparent 20%, transparent 80%, #76a4fb80 80%, #76a4fb80);
  background-image: linear-gradient(to bottom, var(--accent-opaque) 20%, transparent 20%, transparent 80%, var(--accent-opaque) 80%, var(--accent-opaque));
  background-position: 0 1.03em;
  background-repeat: repeat-x;
  font-weight: normal;
}

p a:after, ul:not(.nav) li a:after, a.link:after, nav.sidebar button:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: -3px;
  content: "";
  display: block;
  height: 2px;
  right: 100%;
  left: 0;
  position: absolute;
  background: rgb(79, 129, 223);
  background: var(--accent);
  transition: width 0.2s ease 0s, left 0.2s ease 0s;
  width: 0;
}

p a:hover:after, ul:not(.nav) li a:hover:after, a.link:hover:after, nav.sidebar button:hover:after { 
  width: 100%; 
  right: 0;
}

.container {
  background-color: var(--bg);
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

img.logo {
  max-width: 60px;
  height: 35px;
  margin: 10px 0;
}

div.line {
  width: 3px;
  height: 70px;
  background-color: rgb(79, 129, 223);
  background-color: var(--accent);
}

div.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30px;
  color: rgb(79, 129, 223);
  color: var(--accent);
}

div.page-content {
  max-width: 1200px;
  max-width: var(--max-content-width);
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  padding: 0 80px;
  box-sizing: border-box;
  background-color: var(--bg);
}

div.page-content.center {
  align-items: center;
  margin-top: 170px;
}

img.icon {
  width: 20px;
  height: 20px;
}

*:not(.nav) img.icon {
  padding: 0 10px;
}

img.icon.disabled {
  opacity: 0.2;
  cursor: default;
}

.nav.contact img.icon, img.nav-toggle.icon {
  transition: all 0.2s ease-in-out;
}

.nav.contact img.icon:hover, img.nav-toggle.icon:hover {
  transform: scale(1.25);
  -webkit-filter: grayscale(10%);
          filter: grayscale(10%);
}

div.nav-links::before {
  top: -30%;
  opacity: 0.5;
}

div.nav-links {
  -webkit-animation: 3.85s nav-slide-down ease;
          animation: 3.85s nav-slide-down ease;
  -webkit-animation: var(--nav-slide-duration) nav-slide-down ease;
          animation: var(--nav-slide-duration) nav-slide-down ease;
}

div.contact {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1200px;
  max-width: var(--max-content-width);
  background-color: var(--bg);
  padding: 0 20px;
  box-sizing: border-box;
}

div.contact p.prompt {
  width: auto;
}

.contact ul.nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-wrap: nowrap;
  width: auto;
  margin: 0;
}

@media screen and (max-width: 900px) {
  div.page-content {
    padding: 0 40px;
  }
}

@media screen and (max-width: 500px) {
  * {
    font-size: 10.5pt;
  }

  div.page-content {
    padding: 0 15px;
    padding-top: 35px;
    margin: 60px auto;
    margin-top: 70px;
  }

  div.page-content.center {
    margin-top: 100px;
  }

  .contact.spanning-content {
    flex-wrap: wrap;
  }

  div.contact {
    position: relative;
  }

  div.contact p.prompt {
    width: 100%;
  }

  .contact ul.nav {
    display: none;
  }
}

/** Animations */
@-webkit-keyframes slide-down {
  from {
    height: 0;
    transform: translateY(-30%) scaleY(0);
  }
  to {
    height: 100%;
    transform: translateY(0) scaleY(1);
  }
}
@keyframes slide-down {
  from {
    height: 0;
    transform: translateY(-30%) scaleY(0);
  }
  to {
    height: 100%;
    transform: translateY(0) scaleY(1);
  }
}

@-webkit-keyframes nav-slide-down {
  from {
    top: -30%;
    opacity: 0;
  }
  75% {
    top: -30%;
    opacity: 0;
  }
  to {
    top: 80px;
    opacity: 1;
  }
}

@keyframes nav-slide-down {
  from {
    top: -30%;
    opacity: 0;
  }
  75% {
    top: -30%;
    opacity: 0;
  }
  to {
    top: 80px;
    opacity: 1;
  }
}

@-webkit-keyframes slide-up {
  from {
    opacity: 0.5;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    opacity: 0.5;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes nav-slide-up {
  from {
    bottom: -30%;
    opacity: 0;
  }
  75% {
    bottom: -30%;
    opacity: 0;
  }
  to {
    bottom: 80px;
    opacity: 1;
  }
}

@keyframes nav-slide-up {
  from {
    bottom: -30%;
    opacity: 0;
  }
  75% {
    bottom: -30%;
    opacity: 0;
  }
  to {
    bottom: 80px;
    opacity: 1;
  }
}

@-webkit-keyframes blink-accent {
  from {
    color: rgb(79, 129, 223);
    color: var(--accent);
  }
  50% {
    color: transparent;
  }
  to {
    color: rgb(79, 129, 223);
    color: var(--accent);
  }
}

@keyframes blink-accent {
  from {
    color: rgb(79, 129, 223);
    color: var(--accent);
  }
  50% {
    color: transparent;
  }
  to {
    color: rgb(79, 129, 223);
    color: var(--accent);
  }
}

@-webkit-keyframes shimmer {
  from {
    -webkit-filter: brightness(100%) hue-rotate(0deg);
            filter: brightness(100%) hue-rotate(0deg);
  }
  to {
    -webkit-filter: brightness(150%) hue-rotate(5deg);
            filter: brightness(150%) hue-rotate(5deg);
  }
}

@keyframes shimmer {
  from {
    -webkit-filter: brightness(100%) hue-rotate(0deg);
            filter: brightness(100%) hue-rotate(0deg);
  }
  to {
    -webkit-filter: brightness(150%) hue-rotate(5deg);
            filter: brightness(150%) hue-rotate(5deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    opacity: 0%;
    transform: scale(1);
  }
  80% {
    opacity: 100%;
    transform: scale(1.04);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    opacity: 0%;
    transform: scale(1);
  }
  80% {
    opacity: 100%;
    transform: scale(1.04);
  }
  100% {
    opacity: 100%;
    transform: scale(1);
  }
}

button:not(:disabled):hover {
  -webkit-filter: brightness(110%) hue-rotate(2deg);
          filter: brightness(110%) hue-rotate(2deg);
}
.nav li {
  border-radius: 25px;
  background: var(--bg);
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  margin: 0px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  line-height: normal;
}

.nav:not(.contact) li a {
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
}

.nav li:last-of-type {
  margin-right: 0;
}

.contact .nav li {
  margin: 0;
}

div:not(.contact) .nav li.reverse, .contact-links li.reverse {
  flex-direction: row-reverse;
}

.nav:not(.contact) li:hover, .contact-links li:hover {
  box-shadow: var(--hover-shadow);
  cursor: pointer;
}

.nav li a.active-link {
  -webkit-filter: none;
          filter: none;
}

.nav img.icon {
  padding: 0;
}

@media screen and (max-width: 500px) {
  .nav li {
    box-shadow: var(--hover-shadow);
    padding: 5px 10px;
    margin: 5px 10px;
    margin-right: 0;
  }
}
.page-content.center p {
  text-align: center;
}

img.placeholder {
  max-width: 500px;
  width: 100%;
  opacity: 0.8;
  -webkit-filter: brightness(60%);
          filter: brightness(60%);
  margin: 20px auto;
}

div.alt-links {
  margin: 20px auto;
}
div.page-content {
  justify-content: initial;
}

.about-section {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.about-section p.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  min-width: 130px;
  margin-top: 30px;
}

.about-section ul {
  background-color: var(--element-bg);
  list-style: outside none none;
  padding: 20px;
  padding-left: 0px;
  border-radius: 10px;
  position: relative;
}

.about-section li::before {
  content: "•";
  color: var(--accent);
  display: inline-block;
  width: 1em;
  position: absolute;
  left: 20px;
  font-size: 1.5em;
}

.about-section li {
  padding-left: 40px;
  padding-bottom: 10px;
}

.about-section text {
  /* text-decoration: underline dashed var(--accent) 1px; */
  /* -webkit-text-decoration: underline dashed var(--accent) 1px; */
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-decoration-thickness: 0.1em;
  text-decoration-offset: 3px;
  -webkit-text-decoration-color: var(--accent);
          text-decoration-color: var(--accent);
  position: relative;
}

.about-section .translation-tip {
  display: none;
  position: absolute;
  border-radius: 3px;
  background-color: var(--accent);
  color: var(--bg);
  top: 130%;
  right: 0;
  left: 0;
  line-height: 1em;
  padding: 3.5px;
  text-align: center;
  opacity: 0;
}

.about-section .translation-tip.visible {
  display: block;
  opacity: 1;
  transition: 0.5s opacity ease-in-out;
}

.about-section .translation-tip.visible::before {
  --tooltip-size: 5px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-right: var(--tooltip-size) solid transparent;
  border-left: var(--tooltip-size) solid transparent;
  border-bottom: var(--tooltip-size) solid var(--accent);
  left: calc(50% - var(--tooltip-size));
  top: calc(-1 * var(--tooltip-size));
}

button.tooltip-btn {
  color: var(--accent);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .about-section ul {
    padding: 10px;
    padding-left: 0;
  }

  .about-section li::before {
    left: 10px;
  }

  .about-section li {
    padding-left: 25px;
  }
}
.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.contact-component {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
}

.contact-form input, .contact-form textarea {
  background-color: var(--element-bg);
  margin: 10px 0;
  border-radius: 5px;
  padding: 10px;
  color: var(--text);
  border: 2px solid transparent;
  font-family: var(--font-nunito);
  box-sizing: border-box;
}

.contact-form input:focus, .contact-form textarea:focus {
  border: 2px solid var(--accent);
}

.contact-component label {
  font-family: var(--font-nunito);
}

.contact-component:focus-within label {
  color: var(--accent);
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #101214;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spanning-content {
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.spacer {
  flex: 1 1;
}

[data-aos="disappear"] {
  opacity: 1;
  transition-property: opacity;
}

[data-aos="disappear"].aos-animate {
  opacity: 0;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: var(--bg);
  box-shadow: var(--header-shadow);
  z-index: 3;
  margin: 0 auto;
  max-width: var(--max-content-width);
  padding: 0 20px;
}

h2.logo {
  margin: 10px 0;
}

button.theme-toggle {
  margin: 0;
  position: absolute;
  top: 0;
  right: 20px;
  border: none;
  background: none;
}

img.theme-toggle {
  height: 30px;
  padding: 0 4px;
  transform-origin: top;
  cursor: pointer;
}

img.theme-toggle.switch {
  transform: translateY(-30%);
}

button.theme-toggle:hover img.theme-toggle.switch {
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}

.nav-toggle {
  /* display: none; */
  display: block;
  margin-top: 40px;
  cursor: pointer;
}

.nav-links {
  position: fixed;
  top: 80px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
}

div.page-content {
  text-align: left;
  justify-content: center;
}

div.page-content#center {
  justify-content: center;
  align-items: center;
}

div.page-content p:not() {
  font-family: var(--font-nunito);
}

.page-content .typewritten {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

*#blink {
  -webkit-animation: 1s blink-accent infinite;
          animation: 1s blink-accent infinite;
  margin: 0;
}

.page-content .typewritten h1#name {
  margin: 0;
}

.page-content .home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin-top: 40vh;
  width: 100%;
  box-sizing: border-box;
}

.page-content .home.row, .page-content .home .row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.home .row div {
  padding-right: 15px;
}

.page-content .home.confined {
  max-width: 600px;
}

.page-content .home.connect {
  margin-bottom: 200px;
}

.page-content .home h3,
.page-content .home h2 {
  color: var(--text);
}

.page-content .home h3.section-title {
  color: var(--accent);
  font-family: var(--font-code);
}

.page-content h2 {
  margin: 10px 0;
  margin-bottom: 18px;
}

.button {
  color: var(--primary);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  margin: 50px 0;
  border: 1.5px solid var(--primary);
  font-family: var(--font-nunito);
}

.header .button {
  margin: 10px 0;
  margin-right: 50px;
  padding: 5px 15px;
}

.button:hover {
  border: 1.5px solid var(--accent);
  color: var(--accent);
}

a.button {
  font-weight: 600;
}

.header a.button {
  font-size: 1em;
}

.button img.icon {
  padding-left: 0;
}

.home img#about-img {
  min-width: 35%;
  box-sizing: border-box;
  margin-left: 20px;
}

ul.skill-nav {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
}

ul.skill-nav button {
  border-radius: 20px;
  line-height: auto;
  padding: 5px;
  margin: 0 5px;
  display: flex;
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
}

ul.skill-nav button:hover {
  box-shadow: var(--hover-shadow);
  -webkit-filter: none;
          filter: none;
}

ul.skill-nav li p.title {
  margin: 0;
  display: flex;
  color: var(--accent);
  padding-right: 10px;
}

ul.skill-nav li.selected button {
  -webkit-filter: none;
          filter: none;
  background-color: var(--element-bg);
}

ul.skills {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  align-items: center;
  margin: 0;
}

ul.skills li {
  padding: 10px 20px;
  background-color: var(--element-bg);
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-animation: 0.25s pulse ease-in-out;
          animation: 0.25s pulse ease-in-out;
}

ul.skills p.title img.icon {
  padding-left: 0;
}

div.skill-group {
  min-height: 200px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

div.contact-links {
  position: fixed;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: var(--nav-slide-duration) nav-slide-up ease;
          animation: var(--nav-slide-duration) nav-slide-up ease;
}

p.prompt {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

p.prompt.center {
  margin-top: 150px;
  justify-content: center;
  text-align: center;
  -webkit-animation: 0.8s shimmer ease-in-out alternate infinite;
          animation: 0.8s shimmer ease-in-out alternate infinite;
}

p.prompt:hover {
  color: var(--primary);
}

.contact-links div.line {
  margin-top: 5px;
}

@media screen and (max-width: 900px) {
  ul.skills {
    flex-direction: column;
  }

  ul.skills li {
    min-width: unset;
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 500px) {
  .header {
    align-items: baseline;
    margin-left: 0px;
    box-shadow: none;
  }

  .page-content .home.row, .page-content .home .row {
    flex-direction: column;
  }

  .nav-toggle {
    display: block;
    margin-top: 40px;
    cursor: pointer;
  }
  
  div.contact-links {
    right: 10px;
  }

  .page-content#center h2 {
    font-size: 1.8em;
    white-space: nowrap;
  }

  div.line {
    display: none;
  }

  ul.skill-nav img.icon {
    padding: 0 5px;
  }
}

@-webkit-keyframes stretch {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(1.2);
  }
}

@keyframes stretch {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(1.2);
  }
}
.project-container {
  --radius: 10px;
  --img-height: 55%;
  --info-height: 45%;

  min-width: 275px;
  max-width: 300px;
  height: 30em;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 40px 30px;
  flex: 1 1;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: transform 0.4s, background-image 1s 1s ease-in-out;
}

.project-container:hover {
  transform: rotate(1deg) scale(1.05);
  background-image: url(/static/media/card_hover.237f0626.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.project-container button.link-parent {
  height: 100%;
  margin: 15px;
  background-color: var(--element-bg);
  border-radius: var(--radius);
  box-sizing: border-box;
  position: relative;
}

.project-container:hover button.link-parent {
  box-shadow: var(--hover-shadow);
}

.project-container .image-overlay {
  height: var(--img-height);
  width: 100%;
  border-radius: var(--radius) var(--radius) 0 0;
  background: var(--accent);
  background-blend-mode: luminosity;
  background-size: cover;
}

.project-container:hover .image-overlay {
  background-color: transparent;
}

.project-container img.card-image {
  height: var(--img-height);
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: var(--radius) var(--radius) 0 0;
}

.info {
  padding: 15px;
  text-align: left;
  height: var(--info-height);
  box-sizing: border-box;
}

.info h3 {
  font-weight: 600;
  color: var(--accent);
}

.project-header {
  display: flex;
  flex-direction: row;
}

.project-container img.icon {
  padding-right: 0;
  width: 20px;
}

.project-container img.icon:not(.disabled) {
  cursor: pointer;
}

.project-container img.icon.disabled {
  cursor: default;
}

.tech {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 15px;
  overflow: hidden;
}

p.tech-item {
  /* background-color: var(--primary); */
  color: var(--secondary);
  margin: 0;
  font-weight: 500;
  font-family: var(--font-code);
  border-radius: var(--radius);
  height: 1.3em;
  padding: 0px 15px;
  padding-left: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .project-container {
    width: 90%;
    margin: 40px auto;
    box-sizing: border-box;
  }
}
.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.skill p {
  margin: 0;
}
div.page-content {
  justify-content: initial;
  /* max-width: 1500px; */
}

.projects-parent {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.projects {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}

.projects-parent h3.section-title {
  color: var(--primary);
  font-family: var(--font-nunito);
  margin-left: 30px;
  font-weight: bold;
  font-size: 1.5em;
  padding-top: 50px;
  padding-bottom: 30px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  z-index: 2;
  background-color: var(--bg);
  box-shadow: 0 30px 20px -30px var(--bg-opaque);
}

@media screen and (max-width: 500px) {
  .projects-parent h3.section-title {
    margin-left: 0;
    font-size: 1.7em;
  }
}
div.nav:not(.visible) {
  display: none;
}

.parent:not(.bottom) {
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  z-index: 5;
  transform-origin: top;
}

.parent.bottom {
  position: fixed;
  display: none;
}

div.parent {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 4;
}

div.line {
  height: 100px;
}

ul.nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  margin: 0;
}

.nav p {
  font-family: var(--font-code);
  padding: 0;
  margin: 0 5px;
  cursor: pointer;
  color: var(--accent);
}

.nav a {
  text-decoration: none;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.nav a:hover {
  border-bottom: none;
}

@media screen and (max-width: 500px) {
  div.nav.visible {
    display: block;
    position: fixed;
    top: 65px;
    bottom: 0;
    background: var(--bg);
    margin: 0;
    z-index: 5;
    width: 100%;
    height: 100%;
  }

  div.nav.visible .parent.top, div.nav.visible .parent.bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100px;
    max-width: auto;
    right: 15px;
    left: auto;
  }

  .parent.top {
    top: 85px;
  }

  .parent.bottom {
    display: block;
    bottom: 20px;
  }

  ul.nav {
    width: auto;
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-animation: slide-down 0.15s linear;
            animation: slide-down 0.15s linear;
    transform-origin: top;
  }

  .alt-links ul.nav {
    justify-content: center;
  }

  ul.nav p {
    font-size: 1.15em;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.hidden {
  display: none;
}

.slide-up {
  -webkit-animation: 0.7s slide-up ease;
          animation: 0.7s slide-up ease;
  transform-origin: top;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.view-header {
  display: flex;
  flex-direction: row;
}

.view-header img.icon {
  width: 20px;
}

.banner {
  padding-top: 30px;
  padding-bottom: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
  background-color: var(--bg);
  z-index: 4;
  box-shadow: var(--header-shadow);
}

.banner .spanning-content {
  margin-bottom: 15px;
}

.spanning-content.baseline {
  align-items: baseline;
}

.spanning-content img.icon {
  padding-left: 0;
}

.banner a img.icon:not(.disabled) {
  transition: transform 0.3s ease-in-out;
}

.banner a:hover img.icon:not(.disabled) {
  transform: scale(1.15);
}

nav.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 200px;
  z-index: 4;
}

.sidebar ul {
  list-style: disc inside;
  padding-left: 20px;
  color: var(--accent);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-family: var(--font-code);
  font-weight: 600;
  font-size: 1.1em;
}

.sidebar li {
  padding: 2.5px 0;
}

.sidebar li:first-of-type {
  padding-top: 10px;
}

nav.sidebar li button {
  color: var(--text);
  font-family: var(--font-poppins);
  font-size: 1em;
  font-weight: 500;
}

h2.title {
  color: var(--secondary);
  font-family: var(--font-code);
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.spanning-content.banner .spanning-content h2#blink {
  color: var(--secondary);
}

.project-section .status-icon {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 8px 10px;
}

.project-section .status-icon#wip {
  background-color: var(--color-wip);
}

.project-section .status-icon#live {
  background-color: var(--color-live);
}

.project-section .status-icon#comp {
  background-color: var(--color-comp);
}

.project-section p#wip {
  color: var(--color-wip);
}

.project-section p#live {
  color: var(--color-live);
}

.project-section p#comp {
  color: var(--color-comp);
}

.project-section p b {
  background-image: linear-gradient(to bottom, var(--accent-opaque) 20%, transparent 20%, transparent 80%, var(--accent-opaque) 80%, var(--accent-opaque));
  background-position: 0 1.03em;
  background-repeat: repeat-x;
  font-weight: normal;
}

.img-viewer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 50px;
  margin-top: 100px;
  overflow-x: auto;
  scrollbar-color: var(--secondary) var(--bg);
}

.img-viewer img {
  max-width: 100%;
  min-width: 600px;
  margin: 0 auto;
}

.img-viewer img.main {
  width: 100%;
  height: 250px;
  object-fit: contain;
  margin: 20px 0;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.img-viewer .images img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  overflow: hidden;
  padding: 0 10px;
  cursor: pointer;
  opacity: 0.7;
}

.img-viewer .images img:hover {
  opacity: 1;
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  max-width: 700px;
  scroll-margin: 240px;
}

.baseline .project-section {
  margin-top: 30px;
}

.project-section.right {
  align-self: flex-end;
}

.project-section .project-section { /** nested */
  margin-top: 0;
}

.project-section.multiple {
  /* width: 300px; */
  margin-right: 50px;
}

.project-section.row {
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
}

.project-section p.title, .project-section h4.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  min-width: 65px;
  margin: 0;
  padding-bottom: 25px;
  text-align: left;
}

.project-section p:not(.tech-item) {
  margin: 0;
  text-align: left;
  line-height: 1.75em;
  font-family: var(--font-poppins);
}

p.tech-item {
  max-width: none;
  white-space: normal;
  overflow: visible;
  color: var(--text);
  height: auto;
  line-height: 1.75em;
}

.project-section p.tech-item {
  padding: 0;
}

.project-section img.icon {
  padding: 0;
}

.slide-up .home {
  max-width: 700px;
  margin: 200px auto;
  margin-bottom: 100px;
}

@media screen and (max-width: 900px) {
  .spanning-content.baseline {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 650px) {
  nav.sidebar {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  h2.title {
    font-size: 1.45em;
    white-space: nowrap;
  }

  .banner {
    padding-top: 10px;
    top: 70px;
  }

  .slide-up {
    min-width: 280px;
  }

  .baseline .project-section {
    margin-top: 10px;
  }

  .home .section-title {
    padding-top: 50px;
    padding-bottom: 10px;
    /* margin-bottom: 50px; */
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    background-color: var(--bg);
    width: 100%;
    z-index: 2;
  }

  .project-section {
    margin-top: 40px;
  }
}
div.page-content {
  justify-content: initial;
}

.bg-section {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.skills p.title, .skills a.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  /* padding: 20px; */
  min-width: 130px;
  /* margin-top: 30px; */
  margin: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  /* text-decoration: underline 4px var(--element-bg); */
}
