.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.hidden {
  display: none;
}

.slide-up {
  animation: 0.7s slide-up ease;
  transform-origin: top;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.view-header {
  display: flex;
  flex-direction: row;
}

.view-header img.icon {
  width: 20px;
}

.banner {
  padding-top: 30px;
  padding-bottom: 10px;
  position: sticky;
  top: 50px;
  background-color: var(--bg);
  z-index: 4;
  box-shadow: var(--header-shadow);
}

.banner .spanning-content {
  margin-bottom: 15px;
}

.spanning-content.baseline {
  align-items: baseline;
}

.spanning-content img.icon {
  padding-left: 0;
}

.banner a img.icon:not(.disabled) {
  transition: transform 0.3s ease-in-out;
}

.banner a:hover img.icon:not(.disabled) {
  transform: scale(1.15);
}

nav.sidebar {
  position: sticky;
  top: 200px;
  z-index: 4;
}

.sidebar ul {
  list-style: disc inside;
  padding-left: 20px;
  color: var(--accent);
  width: max-content;
  font-family: var(--font-code);
  font-weight: 600;
  font-size: 1.1em;
}

.sidebar li {
  padding: 2.5px 0;
}

.sidebar li:first-of-type {
  padding-top: 10px;
}

nav.sidebar li button {
  color: var(--text);
  font-family: var(--font-poppins);
  font-size: 1em;
  font-weight: 500;
}

h2.title {
  color: var(--secondary);
  font-family: var(--font-code);
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.spanning-content.banner .spanning-content h2#blink {
  color: var(--secondary);
}

.project-section .status-icon {
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 8px 10px;
}

.project-section .status-icon#wip {
  background-color: var(--color-wip);
}

.project-section .status-icon#live {
  background-color: var(--color-live);
}

.project-section .status-icon#comp {
  background-color: var(--color-comp);
}

.project-section p#wip {
  color: var(--color-wip);
}

.project-section p#live {
  color: var(--color-live);
}

.project-section p#comp {
  color: var(--color-comp);
}

.project-section p b {
  background-image: linear-gradient(to bottom, var(--accent-opaque) 20%, transparent 20%, transparent 80%, var(--accent-opaque) 80%, var(--accent-opaque));
  background-position: 0 1.03em;
  background-repeat: repeat-x;
  font-weight: normal;
}

.img-viewer {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 50px;
  margin-top: 100px;
  overflow-x: auto;
  scrollbar-color: var(--secondary) var(--bg);
}

.img-viewer img {
  max-width: 100%;
  min-width: 600px;
  margin: 0 auto;
}

.img-viewer img.main {
  width: 100%;
  height: 250px;
  object-fit: contain;
  margin: 20px 0;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.img-viewer .images img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  overflow: hidden;
  padding: 0 10px;
  cursor: pointer;
  opacity: 0.7;
}

.img-viewer .images img:hover {
  opacity: 1;
}

.project-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 100px;
  max-width: 700px;
  scroll-margin: 240px;
}

.baseline .project-section {
  margin-top: 30px;
}

.project-section.right {
  align-self: flex-end;
}

.project-section .project-section { /** nested */
  margin-top: 0;
}

.project-section.multiple {
  /* width: 300px; */
  margin-right: 50px;
}

.project-section.row {
  flex-direction: row;
  align-items: flex-start;
  justify-content: start;
}

.project-section p.title, .project-section h4.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  min-width: 65px;
  margin: 0;
  padding-bottom: 25px;
  text-align: left;
}

.project-section p:not(.tech-item) {
  margin: 0;
  text-align: left;
  line-height: 1.75em;
  font-family: var(--font-poppins);
}

p.tech-item {
  max-width: none;
  white-space: normal;
  overflow: visible;
  color: var(--text);
  height: auto;
  line-height: 1.75em;
}

.project-section p.tech-item {
  padding: 0;
}

.project-section img.icon {
  padding: 0;
}

.slide-up .home {
  max-width: 700px;
  margin: 200px auto;
  margin-bottom: 100px;
}

@media screen and (max-width: 900px) {
  .spanning-content.baseline {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 650px) {
  nav.sidebar {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  h2.title {
    font-size: 1.45em;
    white-space: nowrap;
  }

  .banner {
    padding-top: 10px;
    top: 70px;
  }

  .slide-up {
    min-width: 280px;
  }

  .baseline .project-section {
    margin-top: 10px;
  }

  .home .section-title {
    padding-top: 50px;
    padding-bottom: 10px;
    /* margin-bottom: 50px; */
    position: sticky;
    top: 40px;
    background-color: var(--bg);
    width: 100%;
    z-index: 2;
  }

  .project-section {
    margin-top: 40px;
  }
}