.project-container {
  --radius: 10px;
  --img-height: 55%;
  --info-height: 45%;

  min-width: 275px;
  max-width: 300px;
  height: 30em;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 40px 30px;
  flex: 1;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  transition: transform 0.4s, background-image 1s 1s ease-in-out;
}

.project-container:hover {
  transform: rotate(1deg) scale(1.05);
  background-image: url('../assets/card_hover.svg');
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.project-container button.link-parent {
  height: 100%;
  margin: 15px;
  background-color: var(--element-bg);
  border-radius: var(--radius);
  box-sizing: border-box;
  position: relative;
}

.project-container:hover button.link-parent {
  box-shadow: var(--hover-shadow);
}

.project-container .image-overlay {
  height: var(--img-height);
  width: 100%;
  border-radius: var(--radius) var(--radius) 0 0;
  background: var(--accent);
  background-blend-mode: luminosity;
  background-size: cover;
}

.project-container:hover .image-overlay {
  background-color: transparent;
}

.project-container img.card-image {
  height: var(--img-height);
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: var(--radius) var(--radius) 0 0;
}

.info {
  padding: 15px;
  text-align: left;
  height: var(--info-height);
  box-sizing: border-box;
}

.info h3 {
  font-weight: 600;
  color: var(--accent);
}

.project-header {
  display: flex;
  flex-direction: row;
}

.project-container img.icon {
  padding-right: 0;
  width: 20px;
}

.project-container img.icon:not(.disabled) {
  cursor: pointer;
}

.project-container img.icon.disabled {
  cursor: default;
}

.tech {
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 15px;
  overflow: hidden;
}

p.tech-item {
  /* background-color: var(--primary); */
  color: var(--secondary);
  margin: 0;
  font-weight: 500;
  font-family: var(--font-code);
  border-radius: var(--radius);
  height: 1.3em;
  padding: 0px 15px;
  padding-left: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 120px;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .project-container {
    width: 90%;
    margin: 40px auto;
    box-sizing: border-box;
  }
}