div.page-content {
  justify-content: initial;
}

.about-section {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}

.about-section p.title {
  white-space: nowrap;
  font-family: var(--font-code);
  color: var(--accent);
  font-weight: 600;
  min-width: 130px;
  margin-top: 30px;
}

.about-section ul {
  background-color: var(--element-bg);
  list-style: outside none none;
  padding: 20px;
  padding-left: 0px;
  border-radius: 10px;
  position: relative;
}

.about-section li::before {
  content: "•";
  color: var(--accent);
  display: inline-block;
  width: 1em;
  position: absolute;
  left: 20px;
  font-size: 1.5em;
}

.about-section li {
  padding-left: 40px;
  padding-bottom: 10px;
}

.about-section text {
  /* text-decoration: underline dashed var(--accent) 1px; */
  /* -webkit-text-decoration: underline dashed var(--accent) 1px; */
  text-decoration-line: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: 0.1em;
  text-decoration-offset: 3px;
  text-decoration-color: var(--accent);
  position: relative;
}

.about-section .translation-tip {
  display: none;
  position: absolute;
  border-radius: 3px;
  background-color: var(--accent);
  color: var(--bg);
  top: 130%;
  right: 0;
  left: 0;
  line-height: 1em;
  padding: 3.5px;
  text-align: center;
  opacity: 0;
}

.about-section .translation-tip.visible {
  display: block;
  opacity: 1;
  transition: 0.5s opacity ease-in-out;
}

.about-section .translation-tip.visible::before {
  --tooltip-size: 5px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-right: var(--tooltip-size) solid transparent;
  border-left: var(--tooltip-size) solid transparent;
  border-bottom: var(--tooltip-size) solid var(--accent);
  left: calc(50% - var(--tooltip-size));
  top: calc(-1 * var(--tooltip-size));
}

button.tooltip-btn {
  color: var(--accent);
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .about-section {
    flex-direction: column;
  }

  .about-section ul {
    padding: 10px;
    padding-left: 0;
  }

  .about-section li::before {
    left: 10px;
  }

  .about-section li {
    padding-left: 25px;
  }
}