.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
}

.skill p {
  margin: 0;
}